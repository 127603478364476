<template>
  <UiSidePanelForm
    :model-value="modelValue"
    title="Lead Interests"
    description="Please add the lead's main interests so we can help you create the most accurate offer possible."
    primary-button-text="Save"
    secondary-button-text="Cancel"
    :loading
    :disabled="saving"
    @update:model-value="$emit('update:modelValue', false)"
    @confirm="submit"
  >
    <form class="h-full" @submit.prevent>
      <UiInputRadio
        id="ready_to_move"
        v-model="shallowValue.ready_to_move"
        label="Ready to Move"
        :items="readyToMoveItems"
        class="mb-4"
      />
      <div class="flex flex-row items-center gap-4">
        <div class="w-8/12">
          <UiInputGap v-model="shallowBudget" label="Budget" name="Budget" placeholder="Add a budget" class="mb-4" />
        </div>
        <div class="w-4/12">
          <UiInputMenu
            v-model="shallowValue.currency_id"
            :items="currencyItems"
            label="Currency"
            name="Currency"
            placeholder="Find an option"
            class="mb-4"
            :width="200"
            search
            align-right
            input-name="code"
            input-placeholder="AED"
          />
        </div>
      </div>
      <UiInputSelect
        v-model="bedroomsIds"
        :items="useSerializeLibraryItems(librariesStore.getBedrooms)"
        multiple
        label="Bedrooms"
        name="Bedrooms"
        placeholder="Add number of bedrooms"
        class="mb-4"
      />
      <UiInputSelect
        v-model="locationsIds"
        :items="locationItems"
        multiple
        label="Location"
        name="Location"
        placeholder="Add locations"
        class="mb-4"
      />
      <UiInputSelect
        v-model="propertyTypesIds"
        :items="useSerializeLibraryItems(librariesStore.getPropertyTypes)"
        multiple
        label="Property Type"
        name="Property Type"
        placeholder="Add property types"
        class="mb-4"
      />
      <UiInputGapSelect
        v-model="shallowArea"
        :items="placeAreaItems"
        label="Place area"
        name="Place area"
        placeholder="Add an area gap"
        units="Sq.Ft"
        class="mb-4"
      />
      <UiInputSelect
        v-model="amenitiesIds"
        :items="useSerializeLibraryItems(librariesStore.getInterests)"
        multiple
        label="Amenities"
        name="Amenities"
        placeholder="Add amenities"
        class="mb-4"
      />
    </form>
  </UiSidePanelForm>
</template>

<script setup lang="ts">
import sortBy from 'lodash/sortBy'
import omitBy from 'lodash/omitBy'
import { useUiStore } from '@/store/ui'
import type { InputItem, Lead } from '@/types'
import { useLibrariesStore } from '~/store/libraries'

const uiStore = useUiStore()
const librariesStore = useLibrariesStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  leadId: number
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const readyToMoveItems = [
  { text: 'Ready to move', value: true },
  { text: 'Not ready', value: false },
]

const placeAreaItems = [
  { text: '300', value: 300 },
  { text: '400', value: 400 },
  { text: '500', value: 500 },
  { text: '600', value: 600 },
  { text: '700', value: 700 },
  { text: '800', value: 800 },
  { text: '900', value: 900 },
  { text: '1000', value: 1000 },
]

const AED_CURRENCY_ID = 234

const shallowValue = ref<Lead>({})
const currencyItems = ref<InputItem[]>([])
const locationItems = ref<InputItem[]>([])
const loading = ref(true)
const saving = ref(false)
const amenitiesIds = ref<number[]>([])
const locationsIds = ref<number[]>([])
const propertyTypesIds = ref<number[]>([])
const bedroomsIds = ref<number[]>([])
const shallowArea = ref<number[]>([])
const shallowBudget = ref(0)

onNuxtReady(async () => {
  loading.value = true
  await Promise.all([getLead(), useInterests(), getCurrencies(), getLocations(), usePropertyTypes(), useBedrooms()])
  loading.value = false
})

const getLead = async () => {
  const lead = await useGetLead(props.leadId)
  shallowValue.value = { ...lead, currency_id: lead.currency_id || AED_CURRENCY_ID }
  amenitiesIds.value = shallowValue.value.interests?.map((i) => i.id) || []
  locationsIds.value = shallowValue.value.locations?.map((i) => i.id) || []
  propertyTypesIds.value = shallowValue.value.types?.map((i) => i.id) || []
  bedroomsIds.value = shallowValue.value.bedrooms?.map((i) => i.id) || []
  shallowArea.value = [shallowValue.value.area_min || 0, shallowValue.value.area_max || 0]
  shallowBudget.value = shallowValue.value.local_budget_max
    ? Number(shallowValue.value.local_budget_max)
    : shallowValue.value.budget_max
    ? Number(shallowValue.value.budget_max)
    : 0
}

const getCurrencies = async () => {
  const currencies = await useCurrencies()
  currencyItems.value = sortBy(
    currencies.map((c) => ({ text: c.code + ' - ' + c.name, value: c.id, ...c })),
    'name'
  )
}
const getLocations = async () => {
  const locations = await useLocations()
  const unknownItemIndex = locations.findIndex((l) => l.name === 'Unknown')
  locationItems.value = locations.map((l) => ({ text: l.name, value: l.id }))
  if (unknownItemIndex >= 0) {
    const unknownItem = locationItems.value[unknownItemIndex]
    locationItems.value.splice(unknownItemIndex, 1)
    locationItems.value.splice(0, 0, unknownItem)
  }
}

const submit = async () => {
  const leadWithDetails = {
    ...shallowValue.value,
    area_min: shallowArea.value[0],
    area_max: shallowArea.value[1],
    budget_max: shallowBudget.value,
    bedrooms: bedroomsIds.value.map((a) => ({
      id: a,
      name: librariesStore.getBedrooms.find((i) => i.id === a)?.name,
    })),
    interests: amenitiesIds.value.map((a) => ({
      id: a,
      name: librariesStore.getInterests.find((i) => i.id === a)?.name,
    })),
    locations: locationsIds.value.map((l) => ({ id: l, name: locationItems.value.find((i) => i.value === l)?.text })),
    types: propertyTypesIds.value.map((p) => ({
      id: p,
      name: librariesStore.getPropertiesTypes.find((i) => i.id === p)?.name,
    })),
  }
  saving.value = true
  const result = omitBy(leadWithDetails, (v) => v === null || v === undefined || v === 'hidden')
  try {
    const leadUpdated = await useUpdateLead(props.leadId, result)
    uiStore.showSnackBanner('Lead updated successfully')

    emits('input', leadUpdated)
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    saving.value = false
  }
}
</script>

<style scoped></style>
